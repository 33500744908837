import React from 'react';

import linkedin from './assets/linkedin.png';
import github from './assets/github.png';
import './App.css';

import Lottie from 'react-lottie';
import dev from './assets/dev.json';
import { FaGithubSquare, FaLinkedin } from 'react-icons/fa';

function App() {
	return (
		<div className="App">
			<div className='container'>
				<div className='header'>
					<h1>Douglas Lopes</h1>
				</div>
        <div className='illustration'>
          <div className='lottie'>
          <Lottie options={{
              loop: true,
              autoplay: true,
              animationData: dev,
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
              }
            }}
            isStopped={false}
            isPaused={false}
            />
          </div>
        </div>
				<div className="social-content">
					<div>
						<a href="https://www.linkedin.com/in/devdouglas/"><FaLinkedin className="social-logo"/></a>
					</div>
					<div><a href="https://github.com/dev-douglas"><FaGithubSquare className="social-logo"/></a></div>
				</div>
			</div>
		</div>
	);
}

export default App;
